import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AboutContainer = styled.section`
  padding: 6rem 2rem;
  background-color: var(--background-color);
  overflow: hidden;
`;

const AboutContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const AboutTitle = styled(motion.h2)`
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
`;

const AboutText = styled(motion.p)`
  font-size: 1.1rem;
  color: var(--text-color);
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <AboutContainer ref={ref} id="about">
      <AboutContent>
        <AboutTitle
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          About Force Codes
        </AboutTitle>
        <AboutText
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          FORCE CODES is an innovative app studio dedicated to creating utility applications 
          that make a real difference in people's lives. Our mission is to develop world-class 
          apps that are loved and used by people globally.
        </AboutText>
        <AboutText
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          We specialize in creating solutions for Gut Health, Alcohol Cessation Assistance, 
          and Dog Training. Our team is passionate about leveraging technology to improve 
          daily life and promote well-being.
        </AboutText>
      </AboutContent>
    </AboutContainer>
  );
};

export default About;