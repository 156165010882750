import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ProductsContainer = styled.section`
  padding: 6rem 2rem;
  background-color: var(--background-color);
`;

const ProductsContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ProductsTitle = styled(motion.h2)`
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 3rem;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ProductCard = styled(motion.div)`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ProductIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const ProductName = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
`;

const ProductDescription = styled.p`
  font-size: 1rem;
  color: #666;
`;

const Products = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const products = [
    {
      name: "Gut Health Assistant",
      icon: "🥗",
      description: "Personalized nutrition and lifestyle advice for optimal gut health."
    },
    {
      name: "Alcohol Cessation Helper",
      icon: "🚫🍷",
      description: "Support and tools to help you reduce or quit alcohol consumption."
    },
    {
      name: "Dog Training Companion",
      icon: "🐕",
      description: "Interactive guides and progress tracking for effective dog training."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <ProductsContainer ref={ref} id="products">
      <ProductsContent>
        <ProductsTitle
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          Our Products
        </ProductsTitle>
        <ProductGrid
          as={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
        >
          {products.map((product, index) => (
            <ProductCard key={index} variants={itemVariants}>
              <ProductIcon>{product.icon}</ProductIcon>
              <ProductName>{product.name}</ProductName>
              <ProductDescription>{product.description}</ProductDescription>
            </ProductCard>
          ))}
        </ProductGrid>
      </ProductsContent>
    </ProductsContainer>
  );
};

export default Products;