import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const LogoContainer = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
`;

const Cursor = styled.span`
  animation: ${blink} 0.7s infinite;
`;

const AnimatedLogo = () => {
  const [text, setText] = useState('');
  const fullText = 'Force.codes';

  useEffect(() => {
    if (text.length < fullText.length) {
      const timer = setTimeout(() => {
        setText(fullText.slice(0, text.length + 1));
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [text]);

  return (
    <LogoContainer>
      {text}
      {text.length < fullText.length && <Cursor>|</Cursor>}
    </LogoContainer>
  );
};

export default AnimatedLogo;