import React from 'react';
import styled from 'styled-components';

const PolicyContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const PolicyTitle = styled.h1`
  color: var(--primary-color);
`;

const PolicySection = styled.section`
  margin-bottom: 2rem;
`;

const PrivacyPolicy = () => {
  return (
    <PolicyContainer>
      <PolicyTitle>Privacy Policy</PolicyTitle>
      <PolicySection>
        <h2>1. Introduction</h2>
        <p>This Privacy Policy describes how FORCE DİJİTAL HİZMETLER LİMİTED ŞİRKETİ ("we", "our", or "us") collects, uses, and shares your personal information when you use our services.</p>
      </PolicySection>
      <PolicySection>
        <h2>2. Information We Collect</h2>
        <p>We collect information you provide directly to us, such as when you create an account, use our apps, or contact us for support.</p>
      </PolicySection>
      <PolicySection>
        <h2>3. How We Use Your Information</h2>
        <p>We use the information we collect to provide, maintain, and improve our services, to develop new ones, and to protect Force Codes and our users.</p>
      </PolicySection>
      <PolicySection>
        <h2>4. Sharing of Information</h2>
        <p>We do not share personal information with companies, organizations, or individuals outside of Force Codes except in the following cases: with your consent, for external processing, for legal reasons.</p>
      </PolicySection>
      <PolicySection>
        <h2>5. Data Security</h2>
        <p>We work hard to protect Force Codes and our users from unauthorized access to or unauthorized alteration, disclosure, or destruction of information we hold.</p>
      </PolicySection>
      <PolicySection>
        <h2>6. Changes to This Policy</h2>
        <p>We may change this privacy policy from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice.</p>
      </PolicySection>
      <PolicySection>
        <h2>7. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at: Hello@force.codes</p>
      </PolicySection>
    </PolicyContainer>
  );
};

export default PrivacyPolicy;