import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const FeatureContainer = styled.section`
  padding: 6rem 2rem;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  color: white;
`;

const FeatureContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const FeatureTitle = styled(motion.h2)`
  font-size: 3rem;
  margin-bottom: 2rem;
`;

const FeatureText = styled(motion.p)`
  font-size: 1.5rem;
  margin-bottom: 3rem;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FeatureCard = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 10px;
  backdrop-filter: blur(10px);
`;

const FeatureSection = () => {
  return (
    <FeatureContainer>
      <FeatureContent>
        <FeatureTitle
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Apps have the power to change the way we live.
        </FeatureTitle>
        <FeatureText
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          No time for software that wasn't built for you
        </FeatureText>
        <FeatureGrid>
          <FeatureCard
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <h3>Personalized Experience</h3>
            <p>Tailored solutions for your unique needs</p>
          </FeatureCard>
          <FeatureCard
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <h3>Cutting-edge Technology</h3>
            <p>Leveraging the latest advancements in app development</p>
          </FeatureCard>
          <FeatureCard
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <h3>Life-changing Impact</h3>
            <p>Transforming daily routines into extraordinary experiences</p>
          </FeatureCard>
        </FeatureGrid>
      </FeatureContent>
    </FeatureContainer>
  );
};

export default FeatureSection;