import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import AnimatedLogo from './AnimatedLogo';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--header-bg-color);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Nav = styled.nav`
  ul {
    display: flex;
    list-style: none;
    
    li {
      margin-left: 2rem;
      
      a {
        text-decoration: none;
        color: var(--header-text-color);
        font-weight: 500;
        cursor: pointer;
        transition: color 0.3s ease;
        
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <AnimatedLogo />
      <Nav>
        <ul>
          <li><Link to="about" smooth={true} duration={500}>About</Link></li>
          <li><Link to="products" smooth={true} duration={500}>Products</Link></li>
        </ul>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;