import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #f0f0f0;
  color: #333;
  padding: 2rem 0;
  text-align: center;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterText = styled.p`
  margin-bottom: 1rem;
`;

const FooterLinks = styled.div`
  a {
    color: #333;
    margin: 0 10px;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterText>&copy; 2024 FORCE CODES - FORCE DİJİTAL HİZMETLER LİMİTED ŞİRKETİ. All rights reserved.</FooterText>
        <FooterLinks>
          <a href="/privacy">Privacy Policy</a>
          <a href="#contact">Contact Us</a>
        </FooterLinks>
        <FooterText>Email: hello (at) force.codes</FooterText>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;